.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* .btn-arrowDown {
  position: relative;
} */

.btn-arrowDown {
  position: fixed;
  width: 2.5rem; /* Tamaño base para dispositivos pequeños */
  height: 2.5rem; /* Tamaño base para dispositivos pequeños */
  right: 0.5rem; /* Distancia del borde derecho */
  bottom: 0.5rem; /* Distancia del borde inferior */
  margin: 1rem; /* Margen general */
  background-color: #25D366;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.3);
  z-index: 50;
  transition: transform 0.3s ease; /* Transición suave al hacer hover */
}

@media (min-width: 768px) {
  .btn-arrowDown {
    width: 5rem; /* Tamaño para dispositivos medianos y grandes */
    height: 5rem; /* Tamaño para dispositivos medianos y grandes */
    margin: 1.25rem; /* Margen para dispositivos medianos y grandes */
  }
}

/* Tamaño más pequeño del círculo en dispositivos pequeños */
@media (max-width: 767px) {
  .btn-arrowDown::before,
  .btn-arrowDown::after {
    width: 1rem;
    height: 1rem;
  }
}

.btn-arrowDown::before,
.btn-arrowDown::after {
  content: '';
  position: absolute;
  width: 2.3rem;
  height: 2.3rem;
  border-radius: 50%;
  background-color: #25D366;
  opacity: 0;
  animation: waves 3.5s infinite; /* Duración total de la animación aumentada */
}

@media (min-width: 768px) {
  .btn-arrowDown::before,
  .btn-arrowDown::after {
    width: 4rem;
    height: 4rem;
  }
}

.btn-arrowDown::before {
  animation-delay: 1s;
}

.btn-arrowDown::after {
  animation-delay: 2s; /* Intervalo más distante */
}

.btn-arrowDown:hover {
  transform: translateY(-0.5rem); /* Desplazamiento hacia arriba al hacer hover */
}

@keyframes waves {
  0% {
    transform: scale(1);
  }
  10% {
    opacity: 1;
  }
  50% {
    opacity: 0.5; /* Intervalos de tiempo más distantes */
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}


/* .buttonPrueba:hover,
.buttonPrueba:focus{
  outline-offset: 3px;
} */
/* 
.buttonPrueba:is(:hover, :focus){
outline-offset:3px;
} */

/* .modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 9998;
}

.modal-content {
  position: relative;
  width: 90vh;
  max-width: 90%;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  z-index: 9999;
} */
